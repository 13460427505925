import $axios from '@/utils/request'


/**
 * 获取列表
 * @param {*} data 
 * @returns 
 */
export function getEmployeeList(data) {
    const url = '/merchant/user/listEmployee'
    return $axios.fPost(url, data)
}

/**
 * 添加
 * @param {*} data 
 * @returns 
 */
export function addEmployee(data) {
    const url = '/merchant/user/addEmployee'
    return $axios.fPost(url, data)
}
/**
 * 编辑
 * @param {*} data 
 * @returns 
 */
export function editEmployee(data) {
    const url = '/merchant/user/editEmployee'
    return $axios.fPost(url, data)
}

/**
 * 设置状态
 * @param {*} data 
 * @returns 
 */
export function setEnable(data) {
    const url = '/merchant/user/setEnable'
    return $axios.fPost(url, data)
}
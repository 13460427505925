<template>
  <div class="outer-style">
    <el-card :size="size" shadow="never">
      <el-form :inline="true" :model="searchForm">
        <!-- <el-form-item>
          <el-input
            clearable
            v-model="searchForm.loginName"
            placeholder="登录名称"
          ></el-input>
        </el-form-item> -->
        
        <el-form-item>
          <el-input
            clearable
            v-model="searchForm.tel"
            placeholder="手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            v-model="searchForm.name"
            placeholder="员工姓名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="searchForm.state" placeholder="状态">
            <el-option
              v-for="item in options"
              :key="item.key"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <span>
          <!-- <el-button type="primary" @click="openAddModal">添加</el-button> -->
          <el-button @click="resetForm">重置</el-button>
          <el-button type="primary" @click="getListHandler">查询</el-button>
        </span>
      </el-form>
      <el-table :size="size" :border="true" :data="tableData">
        <el-table-column align="center" prop="tel" label="手机号" />
        <el-table-column align="center" prop="name" label="姓名" />
        <!-- <el-table-column align="center" prop="loginName" label="用户名" /> -->
        <el-table-column align="center" prop="state" label="状态">
          <template slot-scope="scope">
            <el-popconfirm
              @onConfirm="confirmStatus(scope.row)"
              @confirm="confirmStatus(scope.row)"
              :title="`确定要${
                statusOptions[scope.row.state == '1' ? '3' : '1']
              }该数据吗？`"
            >
              <el-button type="text" slot="reference">
                {{ statusOptions[scope.row.state] }}
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <el-table-column sortable align="center" prop="createTime" label="创建时间" />
        <el-table-column align="center" prop="action" label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="openEditModal(scope.row)"
              >编辑</el-button
            > -->
            <el-button
              style="margin-left: 8px"
              type="text"
              @click="openDetailModal(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <AddModal ref="add" title="添加" type="primary" @submit="addMember" />
    <EditModal ref="edit" title="编辑" :item="item" @submit="editMember" />
    <DetailModal ref="detail" title="查看" />
  </div>
</template>
<script>
import {
  getEmployeeList,
  addEmployee,
  editEmployee,
  setEnable,
} from "@/api/member/employee";
import AddModal from "@/views/member/AddModal";
import EditModal from "@/views/member/EditModal";
import DetailModal from "@/views/member/DetailModal";
export default {
  components: { AddModal, EditModal, DetailModal },
  data() {
    return {
      item: {},
      title: "",
      size: "small",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: { loginName: null, name: null, tel: null, state: null },
      statusOptions: {
        1: "启用",
        // "2":"删除",
        3: "禁用",
      },
      options: [
        {
          value: "1",
          label: "启用",
        },
        // {
        //   value: '2',
        //   label: '删除',
        // },
        {
          value: "3",
          label: "禁用",
        },
      ],
    };
  },
  created() {
    this.getListHandler();
  },
  methods: {
    /**
     * 获取列表
     */
    async getListHandler() {
      let responseData = await getEmployeeList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { userId, state } = item;
      setEnable({
        userId,
        status: state == "1" ? 3 : 1,
      }).then(() => {
        this.getListHandler();
      });
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        loginName: null,
        name: null,
        tel: null,
        state: null,
      };
      this.getListHandler();
    },
    /**
     * 查看
     */
    handleClick(item) {},
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 打开编辑弹窗
     */
    openEditModal(item) {
      // TODO 添加获取详情
      this.item = item || {};
      this.$nextTick(() => {
        this.$refs.edit.dialogVisible = true;
      });
    },
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.add.initData();
      });
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      this.$refs.detail.initData(item);
      this.$nextTick(() => {
        this.$refs.detail.drawer = true;
      });
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    /**
     * 添加成员
     */
    async addMember(value) {
      const { name, tel, pwd, loginName, state } = value;
      const response = await addEmployee({ name, tel, pwd, loginName, state });
      if (response.state == "success") {
        // this.pageInfo.pageNum = 1;
        // this.pageInfo.pageSize = 10;
        this.$message.success("添加成功");
        this.getListHandler();
        this.$refs.add.dialogVisible = false;
      } else {
        this.$message.warning("添加失败");
      }
    },
    async editMember(value) {
      const { id, name, tel, pwd, state } = value;
      const response = await editEmployee({
        id,
        name,
        tel,
        pwd,
        state,
      });
      if (response.state == "success") {
        this.$message.success("编辑成功");
        //   this.pageInfo.pageNum = 1;
        //   this.pageInfo.pageSize = 10;
        this.getListHandler();
        this.$refs.edit.dialogVisible = false;
      } else {
        this.$message.warning("编辑失败");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
</style>

